import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextareaAutosize,
} from "@mui/material";
import { styled } from "@mui/system";
import Button, { buttonClasses } from "@mui/base/Button";
import CustomDropdown from "./CustomDropdown";
import CustomSelect from "./CustomSelect";
import { Helmet } from "react-helmet";

interface Props {
  handleConvertJson: (e: any) => any;
  handleMinifyJson: (e: any) => any;
  handleSpaceTabChange: (e: any) => any;
  handleValidateJSON: (e: any) => any;
  handleDownload: () => any;
  spaceTab: string;
  spaceTabLists: any;
}

const tabLists = [
  { id: 1, name: "1 Space tab" },
  { id: 2, name: "2 Space tab" },
  { id: 3, name: "3 Space tab" },
  { id: 4, name: "4 Space tab" },
];
export default function Middle(props: Props) {
  return (
    <Grid item xs={12} md={2}>
      <CardActionArea component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <CustomButton
            onClick={props.handleConvertJson}
            style={{ width: "100%" }}
          >
            Convert / Beautify
          </CustomButton>
        </Card>
      </CardActionArea>
      <CardActionArea style={{ marginTop: 10 }} component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <CustomButton
            onClick={props.handleMinifyJson}
            style={{ width: "100%" }}
          >
            Minify / Compact
          </CustomButton>
        </Card>
      </CardActionArea>
      <CardActionArea style={{ marginTop: 10 }} component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <Helmet>
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5995036320118827"
              crossOrigin="anonymous"
            ></script>
          </Helmet>
        </Card>
      </CardActionArea>
      <CardActionArea style={{ marginTop: 10 }} component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <CustomButton
            onClick={props.handleValidateJSON}
            style={{ width: "100%" }}
          >
            Validate
          </CustomButton>
        </Card>
      </CardActionArea>
      <CardActionArea style={{ marginTop: 10 }} component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <CustomButton
            onClick={props.handleDownload}
            style={{ width: "100%" }}
          >
            Download
          </CustomButton>
        </Card>
      </CardActionArea>
      <CardActionArea style={{ marginTop: 10 }} component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <CustomSelect
            handleSpaceTabChange={props.handleSpaceTabChange}
            spaceTab={props.spaceTab}
            spaceTabLists={props.spaceTabLists}
            title={"Space tab"}
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}

const blue = {
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
};

const grey = {
  100: "#eaeef2",
  300: "#afb8c1",
  900: "#24292f",
};

const CustomButton = styled(Button)(
  ({ theme }) => `
    font-family: IBM Plex Sans, sans-serif;
    font-weight: bold;
    font-size: 0.875rem;
    background-color: ${blue[500]};
    padding: 12px 24px;
    
    color: white;
    transition: all 150ms ease;
    cursor: pointer;
    border: none;
    box-shadow: 0px 4px 30px ${
      theme.palette.mode === "dark" ? grey[900] : grey[100]
    };
  
    &:hover {
      background-color: ${blue[600]};
    }
  
    &.${buttonClasses.active} {
      background-color: ${blue[700]};
    }
  
    &.${buttonClasses.focusVisible} {
      box-shadow: 0 3px 20px 0 rgba(61, 71, 82, 0.1), 0 0 0 5px rgba(0, 127, 255, 0.5);
      outline: none;
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
    `
);
