import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { TextareaAutosize } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
interface Props {
  value: string;
}

export default function Right(props: Props) {
  return (
    <Grid item xs={12} md={5}>
      <CardActionArea component="a" href="#">
        <Card sx={{ display: "flex" }}>
          {/* <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            style={{ width: "100%", minHeight: "200px" }}
            value={props.value}
          /> */}
          <CodeMirror
            value={props.value}
            height="500px"
            style={{ width: "100%", textAlign: "left", fontSize: "12px" }}
            extensions={[json(), EditorView.lineWrapping]}
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}
