import React, { useState } from "react";
import "./App.css";
import Blog from "./components/Blog";

function App() {
  const [finalJson, setFinalJson] = useState("");
  const [originJson, setOriginJson] = useState("");
  const handleConvertJson = (value: string) => {
    console.log("onclick");
    const jsonPretty = JSON.stringify(JSON.parse(value), null, 2);
    setFinalJson(jsonPretty);
  };

  return (
    <div className="App">
      <Blog />
      {/* <header className="">
        <textarea
          id="w3review"
          name="w3review"
          value={originJson}
          onChange={(e) => setOriginJson(e.target.value)}
        ></textarea>
        <button type="button" onClick={() => handleConvertJson(originJson)}>
          Convert Beauty Json
        </button>

        <textarea id="w3review" value={finalJson} name="w3review"></textarea>
      </header> */}
    </div>
  );
}

export default App;
