import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import GitHubIcon from "@mui/icons-material/GitHub";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Header from "./Header";
import Left from "./Left";
import Right from "./Right";
import Middle from "./Middle";
import Main from "./Main";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

const sections = [
  { title: "Technology", url: "#" },
  { title: "Design", url: "#" },
  { title: "Culture", url: "#" },
  { title: "Business", url: "#" },
  { title: "Politics", url: "#" },
  { title: "Opinion", url: "#" },
  { title: "Science", url: "#" },
  { title: "Health", url: "#" },
  { title: "Style", url: "#" },
  { title: "Travel", url: "#" },
];

const posts: any = [];

const sidebar = {
  title: "About",
  description:
    "Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.",
  archives: [
    { title: "March 2020", url: "#" },
    { title: "February 2020", url: "#" },
    { title: "January 2020", url: "#" },
    { title: "November 1999", url: "#" },
    { title: "October 1999", url: "#" },
    { title: "September 1999", url: "#" },
    { title: "August 1999", url: "#" },
    { title: "July 1999", url: "#" },
    { title: "June 1999", url: "#" },
    { title: "May 1999", url: "#" },
    { title: "April 1999", url: "#" },
  ],
  social: [
    { name: "GitHub", icon: GitHubIcon },
    { name: "Twitter", icon: TwitterIcon },
    { name: "Facebook", icon: FacebookIcon },
  ],
};

const theme = createTheme();

const spaceTabLists = [
  { id: 1, label: "1 Space tab" },
  { id: 2, label: "2 Space tab" },
  { id: 3, label: "3 Space tab" },
  { id: 4, label: "4 Space tab" },
];

export default function Blog() {
  const [finalJson, setFinalJson] = useState("");
  const [originJson, setOriginJson] = useState("");
  const [spaceTab, setSpactTab] = React.useState<string>("2");

  const onChangeOriginJSON = React.useCallback(
    (value: any, viewUpdate: any) => {
      console.log("value:", value);
      setOriginJson(value);
    },
    []
  );

  const handleConvertJson = () => {
    try {
      const jsonPretty = JSON.stringify(
        JSON.parse(originJson),
        null,
        parseInt(spaceTab)
      );
      setFinalJson(jsonPretty);
    } catch (error: any) {
      setFinalJson(error.message);
    }
  };

  const handleMinifyJson = () => {
    try {
      const jsonPretty = JSON.stringify(JSON.parse(originJson));
      setFinalJson(jsonPretty);
    } catch (error: any) {
      setFinalJson(error.message);
    }
  };

  const handleSpaceTabChange = (event: SelectChangeEvent<typeof spaceTab>) => {
    const {
      target: { value },
    } = event;
    setSpactTab(value);

    try {
      const jsonPretty = JSON.stringify(
        JSON.parse(originJson),
        null,
        parseInt(value)
      );
      setFinalJson(jsonPretty);
    } catch (error: any) {
      setFinalJson(error.message);
    }
  };

  useEffect(() => {}, [finalJson]);

  const [showValid, setShowValid] = React.useState(false);
  const [showInvalid, setShowInvalid] = React.useState(false);

  const handleValidateJSON = () => {
    try {
      JSON.parse(originJson);
      setShowValid(true);
    } catch (e) {
      setShowInvalid(true);
      return false;
    }
  };
  const handleDownload = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      finalJson
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "formatted.json";

    link.click();
  };

  const handleCloseValidateJSON = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowValid(false);
    setShowInvalid(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="JSON Formatter" sections={sections} />
        <main style={{ marginTop: 50 }}>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showValid}
            autoHideDuration={6000}
            onClose={handleCloseValidateJSON}
          >
            <Alert
              onClose={handleCloseValidateJSON}
              severity={"success"}
              sx={{ width: "100%" }}
            >
              Your JSON is valid.
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showInvalid}
            autoHideDuration={6000}
            onClose={handleCloseValidateJSON}
          >
            <Alert
              onClose={handleCloseValidateJSON}
              severity={"error"}
              sx={{ width: "100%" }}
            >
              Your JSON is invalid.
            </Alert>
          </Snackbar>

          <Grid container spacing={4}>
            <Left value={originJson} onChangeOriginJSON={onChangeOriginJSON} />
            <Middle
              handleConvertJson={handleConvertJson}
              handleMinifyJson={handleMinifyJson}
              handleSpaceTabChange={handleSpaceTabChange}
              handleValidateJSON={handleValidateJSON}
              handleDownload={handleDownload}
              spaceTab={spaceTab}
              spaceTabLists={spaceTabLists}
            />
            <Right value={finalJson} />
          </Grid>
        </main>
      </Container>
      <Footer title="" description="" />
    </ThemeProvider>
  );
}
