import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { TextareaAutosize } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";

interface Props {
  value: string;
  onChangeOriginJSON: (value: any, viewUpdate: any) => void;
}

export default function Left(props: Props) {
  return (
    <Grid item xs={12} md={5}>
      <CardActionArea component="a" href="#">
        <Card sx={{ display: "flex" }}>
          <CodeMirror
            placeholder='Paste JSON here":"❤️"'
            value={props.value}
            height="500px"
            style={{
              width: "100%",
              textAlign: "left",
              fontSize: "12px",
              wordWrap: "break-word",
            }}
            extensions={[json(), EditorView.lineWrapping]}
            onChange={props.onChangeOriginJSON}
          />
        </Card>
      </CardActionArea>
    </Grid>
  );
}
