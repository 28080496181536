import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //   width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight: theme.typography.fontWeightMedium,
  };
}

export default function CustomSelect(props: any) {
  const theme = useTheme();

  return (
    <div style={{ width: "100%" }}>
      <Select
        style={{ width: "100%" }}
        displayEmpty
        value={props.spaceTab}
        onChange={props.handleSpaceTabChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{props.title ?? "Place Holder"}</em>;
          }
          const selectedTab = props.spaceTabLists.filter(
            (data: any) => data.id == selected
          );
          if (selectedTab.length > 0) {
            return selectedTab[0].label;
          }
        }}
        MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>{props.title ?? "Place Holder"}</em>
        </MenuItem>
        {props.spaceTabLists.map((data: any) => {
          return (
            <MenuItem
              key={data.id}
              value={data.id}
              style={getStyles(data.label, props.spaceTab, theme)}
            >
              {data.label}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}
